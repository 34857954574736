import * as React from "react"
import { useEffect, useState } from "react";
import { navigate } from "gatsby";
import axios from 'axios';
import Workspace from "../components/workspace";
import {
  Content,
  PageLayout,
  TopNavigation,
} from '@atlaskit/page-layout';
import NavBar from "../components/navbar";
import PageHeader from '@atlaskit/page-header';
import Spinner from '@atlaskit/spinner';
import Button from '@atlaskit/button/standard-button';
import { BitbucketIcon } from '@atlaskit/logo';
import mainStore from "../stores/mainStore";
import "../styles.css";
import { observer } from "mobx-react-lite";
import authService from "../services/authService";
import { IAccessData } from "../models/accessData";
import workspaceService from "../services/workspaceService";
import { IWorkspace } from "../models/workspace";
import { Card } from "@material-ui/core";
import Avatar from "@atlaskit/avatar";
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from '@atlaskit/modal-dialog';
import CrossIcon from '@atlaskit/icon/glyph/cross';
import Lozenge from '@atlaskit/lozenge';
import Radium from "radium";
import LargeAvatar from "../components/largeAvatar";
import SubscriptionCard from "../components/subscriptionCard";


const Subscriptions = () => {

  const isBrowser = () => typeof window !== "undefined";
  let accessData: IAccessData = mainStore.accessData;

  let workspaces = mainStore.workspaces;
  const [ownedWorkspaces, setOwnedWorkspaces] = useState<IWorkspace[]>(workspaces);

  const Paddle = isBrowser() && window.Paddle;

  if (Paddle) {
    if (!mainStore.isProduction) Paddle.Environment.set("sandbox");
    Paddle.Setup({ vendor: parseInt(mainStore.paddleVendorId) });
  }

  useEffect(() => {
    const initialize = async () => {
      if (!isBrowser()) return;
      
      if (!accessData.accessToken) {
        let params = new URLSearchParams(document.location.search);
        let code = params.get("code");
        
        const previousCode = localStorage.getItem("previous_code");
        if (code) {
          if (code!==previousCode) {
            localStorage.setItem("previous_code", code);
            if (!await authService.getAccessTokenFromAuth(code)) {
              navigate("/");
              return;
            }
          } else {
            if (!await authService.getAccessTokenFromRefresh()) {
              navigate("/");
              return;
            }
          }
        } else {
          if (!await authService.getAccessTokenFromRefresh()) {
              navigate("/");
              return;
          }
        }
      }

      await authService.getUserFromBitbucket();
      const repos = await getRepositoriesFromBitbucket();
      await workspaceService.getWorkspacesFromAutocompress(repos as any[]);
      console.log("workspacessssssssssss");
      console.log(workspaces);
      const ownedClientKeys = await workspaceService.getClientKeysFromOwnedWorkspaces();
      setOwnedWorkspaces(workspaces.filter((workspace: IWorkspace) => ownedClientKeys.includes(workspace?.clientKey!)));
      setLoading(false);
    }
    initialize();    
  }, []);

  const filterReposWithAdminPermission = async (response:any) => {
    return new Promise(async (resolve, reject) => {
      let newRepositoriesList:any[] = [];
      response.data.values.forEach((value:any) => {
        if (value.permission == "admin") {
          const repository:any = value.repository;
          newRepositoriesList.push(repository);
        }
      });
      resolve(newRepositoriesList);
    });
  }

  const getRepositoriesFromBitbucket = async () => {
    return new Promise(async (resolve, reject) => {
      let nextPage;
      const response:any = await axios.post(mainStore.proxyUrl, {
        url: `https://api.bitbucket.org/2.0/user/permissions/repositories`,
        method: "GET",
        headers: {
          'Authorization': `Bearer ${accessData.accessToken}`,
          'Accept': 'application/json'
        }
      });
      let repos:any[] = await filterReposWithAdminPermission(response) as any[];
      nextPage = response.data.next;
      while (nextPage) {
        const nextPageResponse:any = await axios.post(mainStore.proxyUrl, {
          url: `https://api.bitbucket.org/2.0/user/permissions/repositories?page=${nextPage}`,
          method: "GET",
          headers: {
            'Authorization': `Bearer ${accessData.accessToken}`,
            'Accept': 'application/json'
          }
        });
        const filteredRepos:any[] = await filterReposWithAdminPermission(nextPageResponse) as any[];
        repos.push(...filteredRepos);
        nextPage = nextPageResponse.data.next;
      }
      resolve(repos);
    });
  }

  const upgradeSubscription = async (newPlanId: string) => {
    // console.log("upgradeSubscription:");
    // console.log(newPlanId);
    // console.log(selectedWorkspace?.subscription?.subscription_id!);
    const response:any = await axios.post(mainStore.proxyUrl, {
      url: `${mainStore.paddleUrl}/api/2.0/subscription/users/update`,
      params: `vendor_id=${mainStore.paddleVendorId}&vendor_auth_code=${mainStore.paddleVendorAuthCode}&subscription_id=${parseInt(selectedWorkspace?.subscription?.subscription_id!)}&plan_id=${parseInt(newPlanId)}&bill_immediately=true&prorate=true`,
      method: "POST",
      headers: {'Content-Type': 'application/x-www-form-urlencoded'},
    });
    console.log(response);
  }

  const [width, setWidth] = useState(isBrowser() ? window.innerWidth / 1.5 : "66%");
  const [loading, setLoading] = useState<boolean>(true);
  const [upgradeModal, setUpgradeModal] = useState<boolean>(false);
  const [cancelModal, setCancelModal] = useState<boolean>(false);
  const [selectedWorkspace, setSelectedWorkspace] = useState<IWorkspace | undefined>(undefined);

  useEffect(() => {
      const handleWindowResize = () => setWidth(isBrowser() ? window.innerWidth / 1.5 : "66%")
      isBrowser() && window.addEventListener("resize", handleWindowResize);

      if (isBrowser())
      return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  const openCheckout = async (planId: number) => {
    closeAllModals();
    Paddle.Checkout.open({
      product: planId,
      customData: {
        "clientKey": selectedWorkspace?.clientKey,
      }
    });
  }

  const upgrade = (workspace: IWorkspace) => {
    setSelectedWorkspace(workspace);
    setCancelModal(false);
    setUpgradeModal(true);
  };

  const cancel = (workspace: IWorkspace) => {
    setSelectedWorkspace(workspace);
    setUpgradeModal(false);
    setCancelModal(true);
  }

  const update = () => {
    closeAllModals();
    Paddle.Checkout.open({
      override: selectedWorkspace?.subscription.update_url,
    });
  }

  const closeAllModals = () => {
    setCancelModal(false);
    setUpgradeModal(false);
  }

  return (
          <PageLayout>
        <TopNavigation
            testId="topNavigation"
            id="product-navigation"
            skipLinkTitle="Product Navigation"
            height={60}
            isFixed={false}
        >
            <NavBar/>
        </TopNavigation>
        <Content testId="content">
          <div style={{
            display: "flex",
            width: "100%",
            justifyItems: "center",
            justifyContent: "center" }}>
            <div style={{paddingTop: 15, width: width}}>
              <PageHeader>
                {ownedWorkspaces.length < 1 && !loading ? "Manage your subscriptions" : "Manage your subscriptions"}
              </PageHeader>
              <Radium.StyleRoot>
                <LargeAvatar/>
              </Radium.StyleRoot>
              {/* <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "start", marginBottom: 50, ...rearrageOnMobile}}>
                <Avatar size="xlarge" src={mainStore.user?.avatar} />
                <div style={{ marginLeft: 20, display: "flex", flexDirection: "column", flex: 3 }}>
                  <span style={{ color: "#253858", fontWeight: "bold", fontSize: 26, textAlign: "left" }}>{`${mainStore.user?.display_name}`}</span>
                  <span style={{ color: "#253858", fontWeight: "normal", fontSize: 24, textAlign: "left" }}>{`${mainStore.user?.username}`}</span>
                </div>
              </div> */}
              {
                ownedWorkspaces.length > 0
                ? ownedWorkspaces.map((workspace, index) => {
                    return <Radium.StyleRoot>
                        <SubscriptionCard
                          workspace={workspace}
                          loading={loading}
                          upgrade={upgrade}
                          cancel={cancel}
                          update={update}
                        />
                      </Radium.StyleRoot>
                  })
                : loading
                  ? <div><Spinner size={30} interactionName="load" /></div>
                  : <div style={{display: "flex", flexDirection: "column", width: "70%" }}>
                      <div> Either Autocompress is not installed on any of your workspaces or you don't have permissions to manage their subscriptions. You will need to contact your workspace admins to install Autocompress or modify the subscriptions.</div>
                    </div>
              }

            </div>
          </div>
          <ModalTransition>
                {upgradeModal && (
                <Modal width="x-large" onClose={closeAllModals} shouldScrollInViewport={true}>
                    <ModalHeader>
                      <ModalTitle>Select your new plan:</ModalTitle>
                      <Button appearance="subtle" onClick={closeAllModals}>
                        <CrossIcon
                          label="Close Modal"
                        />
                      </Button>
                    </ModalHeader>
                    <ModalBody>
                    <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{
              position: "relative", 
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              maxWidth: 1500,
              width: "100%",
            }}>
                {/* <h1 style={{ textAlign:"center", minWidth: 300, width: "60%", color: "#253858", fontWeight: "bold", fontSize: 35 }}>Start with a free 14-day trial</h1> */}
                <span style={{ textAlign:"center", minWidth: 250, width: "60%", color: "#253858", fontSize: 14, fontWeight: "normal", paddingLeft: 10, paddingRight: 10 }}>
                  Plans are based on the number of private repositories being compressed, while compression is always free for public repositories. Each plan covers only one workspace (ie. if you need to compress repos in 2 workspaces, you will need 2 subscriptions). <br/>
                </span>
                <div style={{
                  paddingTop: 30,
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: 'wrap',
                  width: "100%",
                  alignItems: "start",
                  justifyContent: "center"
                  }}>
                    {mainStore.paddlePlans.map((plan: any) => {
                            const upgrading = selectedWorkspace?.subscription.subscribed ? parseInt(selectedWorkspace?.subscription.plan?.subscription_plan_id!) < plan.id : true;
                            if (selectedWorkspace?.subscription.plan?.subscription_plan_id!==plan.id.toString()) {
                              return <Card elevation={2} style={{ margin: 10}}>
                                <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "start", padding: 10 }}>
                                <img src={plan.img} style={{ margin: "auto", maxWidth: 150}}></img>

                                  <h3 style={{ color: "#253858", fontWeight: "normal", fontSize: 32, textAlign: "center" }}>{`${plan.name}`}</h3>
                                  <span style={{ color: "#253858", fontSize: 16, fontWeight: "normal", textAlign: "center" }}>
                                    {`Up to ${plan.repos} repositories`}<br/>
                                    {`Unlimited users`}<br/>
                                  </span>
                                  <span style={{ color: "#253858", margin: 20, fontSize: 20, fontWeight: "bold", textAlign: "center" }}>
                                  {`${plan.price}/month`}
                                  </span>
                                  <Button appearance="primary" onClick={() => openCheckout(plan.id)}
                                  style={{ margin: 20,  width: "80%" }}
                                  >{selectedWorkspace?.subscription.subscribed ? upgrading ? "Upgrade" : "Downgrade" : "Subscribe"}</Button>
                                </div>
                              </Card>
                            }
                        })}
                </div>
            <div style={{ height: 30 }}></div>
            <span style={{ color: "#4A6798", fontSize: 16, fontWeight: "normal", textAlign: "center" }}>
              * Prices on USD. Taxes may apply.<br/>Secure payment via Paddle.
            </span>
            <div style={{ height: 30 }}></div>
            
          </div>
        </div>
                    {/* <span style={{ color: "#253858", fontSize: 16, fontWeight: "normal" }}>
                      Plans are based on the number of repositories that need compressing. <br/>
                    </span>
                      <div style={{
                        paddingTop: 20,
                        paddingBottom: 20,
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        alignItems: "start",
                        justifyContent: "center"
                        }}>
                          {mainStore.paddlePlans.map((plan) => {
                            if (selectedWorkspace?.subscription.plan?.subscription_plan_id!==plan.id.toString()) {
                              const upgrading = parseInt(selectedWorkspace?.subscription.plan?.subscription_plan_id!) < plan.id;
                              return <Card elevation={1} style={{ margin: 10}}>
                                <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "start", padding: 10 }}>
                                  <h3 style={{ color: "#253858", fontWeight: "normal", fontSize: 32, textAlign: "center" }}>{`${plan.name}`}</h3>
                                  <span style={{ color: "#253858", fontSize: 16, fontWeight: "normal", textAlign: "center" }}>
                                    {`Up to ${plan.repos} repositories`}<br/>
                                    {`Unlimited users`}<br/>
                                  </span>
                                  <span style={{ color: "#253858", margin: 20, fontSize: 20, fontWeight: "bold", textAlign: "center" }}>
                                  {`${plan.price}/month`}
                                  </span>
                                  <Button isDisabled={loading} appearance={upgrading ? "primary" : "default"} onClick={() => upgradeSubscription(plan.id.toString())}
                                  style={{ margin: 20,  width: "80%" }}
                                  >{upgrading ? "Upgrade" : "Downgrade"}</Button>
                                </div>
                              </Card>
                            }
                        })}
                      </div> */}
                    </ModalBody>
                    {/* <ModalFooter>
                    <Button appearance="subtle" onClick={closeInitModal}>
                        Cancel
                    </Button>
                    <Button appearance="primary" onClick={submitInitModal} autoFocus>
                        {mainStore.user ? "Upgrade" : "Subscribe"}
                    </Button>
                    </ModalFooter> */}
                </Modal>
                )}
          </ModalTransition>
          <ModalTransition>
                {cancelModal && (
                <Modal onClose={closeAllModals} shouldScrollInViewport={true}>
                    <ModalHeader>
                      <ModalTitle appearance="danger">You are about to lose access to Autocompress</ModalTitle>
                      <Button appearance="subtle" onClick={closeAllModals}>
                        <CrossIcon
                          label="Close Modal"
                        />
                      </Button>
                    </ModalHeader>
                    <ModalBody>
                    <span style={{ color: "#253858", fontSize: 16, fontWeight: "normal" }}>
                      Cancelling the subscription will stop recurring payments immediately. Autocompress will keep compressing the images on this workspace until the last day of the current subscription cycle. After that, you will only be able to compress open source repositories.<br/><br/>Do you still want to continue? <br/>
                    </span>
                    </ModalBody>
                    <ModalFooter>
                    <Button appearance="subtle" onClick={closeAllModals}>
                        No, go back
                    </Button>
                    <Button appearance="danger" onClick={() => {
                      closeAllModals();
                      Paddle.Checkout.open({
                        override: selectedWorkspace?.subscription.cancel_url,
                      });
                    }} autoFocus>
                        {"Yes, cancel my subscription"}
                    </Button>
                    </ModalFooter>
                </Modal>
                )}
          </ModalTransition>
        </Content>
    </PageLayout>

  )
}

export default observer(Radium(Subscriptions));
