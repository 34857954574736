import * as React from "react"
import mainStore from "../stores/mainStore";
import "../styles.css";
import { observer } from "mobx-react-lite";
import Avatar from "@atlaskit/avatar";
import Radium from "radium";


const LargeAvatar = () => {


  const rearrageOnMobile = {
    "@media (max-width: 450px)": {
        flexDirection: "column"
    }
  };

  return (
    <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "start", marginBottom: 50, ...rearrageOnMobile}}>
        <Avatar size="xlarge" src={mainStore.user?.avatar} />
        <div style={{ marginLeft: 20, display: "flex", flexDirection: "column", flex: 3 }}>
            <span style={{ color: "#253858", fontWeight: "bold", fontSize: 26, textAlign: "left" }}>{`${mainStore.user?.display_name}`}</span>
            <span style={{ color: "#253858", fontWeight: "normal", fontSize: 24, textAlign: "left" }}>{`${mainStore.user?.username}`}</span>
        </div>
    </div>
  )
}

export default observer(Radium(LargeAvatar));
