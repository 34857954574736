import * as React from "react"
import { useEffect, useState } from "react";
import { navigate } from "gatsby";
import axios from 'axios';
import Workspace from "../components/workspace";
import {
  Content,
  PageLayout,
  TopNavigation,
} from '@atlaskit/page-layout';
import NavBar from "../components/navbar";
import PageHeader from '@atlaskit/page-header';
import Spinner from '@atlaskit/spinner';
import Button from '@atlaskit/button/standard-button';
import { BitbucketIcon } from '@atlaskit/logo';
import mainStore from "../stores/mainStore";
import "../styles.css";
import { observer } from "mobx-react-lite";
import authService from "../services/authService";
import { IAccessData } from "../models/accessData";
import workspaceService from "../services/workspaceService";
import { IWorkspace } from "../models/workspace";
import { Card } from "@material-ui/core";
import Avatar from "@atlaskit/avatar";
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from '@atlaskit/modal-dialog';
import CrossIcon from '@atlaskit/icon/glyph/cross';
import Lozenge from '@atlaskit/lozenge';
import Radium from "radium";
import LargeAvatar from "../components/largeAvatar";

interface SubscriptionCardProps {
    workspace: any;
    loading: boolean,
    upgrade: (workspace: any) => void;
    cancel: (workspace: any) => void;
    update: () => void;
}


const SubscriptionCard = (props: SubscriptionCardProps) => {

  const workspace = props.workspace;
  const loading = props.loading;

  const rearrageOnMobile1 = {
    "@media (max-width: 1000px)": {
        flexDirection: "column",
        paddingTop: 30,
        textAlign: "center"
      }
    };

    const rearrageOnMobile2 = {
        "@media (max-width: 750px)": {
            flexDirection: "column",
            paddingTop: 30,
            textAlign: "center"
          }
        };

    const centerOnMobile = {
        "@media (max-width: 1000px)": {
            paddingTop: 20,
            textAlign: "center"
          }
        };

  return (
    <Card elevation={1} style={{ width: "100%", marginBottom: 20}}>
        <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "start", padding: 20, ...rearrageOnMobile1}}>
            <Avatar size="large" src={workspace?.avatar} />
            <div style={{ display: "flex", flexDirection: "column", flex: 3, marginLeft: 20 }}>
            <span style={{ color: "#253858", fontWeight: "bold", fontSize: 18, textAlign: "left", ...centerOnMobile}}>{`${workspace?.name}`}</span>
            <div>
            {!workspace.subscription.subscribed ? <Lozenge appearance="removed">NOT SUBSCRIBED</Lozenge> : null}
            {workspace.subscription.status === "past_due" ? <Lozenge appearance="removed">PAYMENT FAILED</Lozenge> : null}
            {workspace.subscription.status === "paused" ? <Lozenge appearance="removed">PAUSED</Lozenge> : null}
            {workspace.subscription.subscribed
            ? <span style={{ color: "#253858", fontWeight: "normal", fontSize: 14, textAlign: "left" }}>{` ${workspace?.subscription?.plan?.name} - ${workspace?.subscription?.plan?.repos_allowed.toString()} repositories`}</span>
            : <span style={{ color: "#253858", fontWeight: "normal", fontSize: 14, textAlign: "left" }}>{` Free plan - Only public repos allowed.`}</span>}
            </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", paddingTop: 25, ...rearrageOnMobile2 }}>
            <Button isDisabled={loading || !workspace.subscription.subscribed} appearance="subtle" onClick={() => props.cancel(workspace)}
            
            >{"Cancel subscription"}</Button>
            <Button isDisabled={loading || !workspace.subscription.subscribed} appearance="subtle" onClick={() => props.update()}
            
            >{"Update details"}</Button>
            <Button isDisabled={loading} appearance="primary" onClick={() => props.upgrade(workspace)}
            
            >{workspace.subscription.subscribed ? "Upgrade plan" : "Subscribe"}</Button>
            </div>
        </div>
    </Card>

  )
}

export default observer(Radium(SubscriptionCard));
